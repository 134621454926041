export const environment = {
  appName: 'bookingpad',
  name: 'sandbox',
  production: true,
  ndcApiEndpoint: 'https://api.airgateway.net/',
  ndcApiEndpointV2: 'https://api.airgateway.net/v2.0/',
  keycloakUrl: 'https://auth.airgateway.net',
  hashVersion: 'e300d5b0',
  coviewAppKey: 'UtgDnY7wWIk',
  domain: '.net',
  default: {
    logoName: 'bookingpad.svg',
    title: 'BookingPad :: NDC-enabled Booking Tool',
    showSearch: true,
    showOrders: true,
    showSubagencies: true,
    showFooter: true,
    showPrivacy: true,
    showCoView: true,
    showSettings: true,
    showCorporates: true,
    showMaintenancePage: false
  },
  frontendDependencies: [{
    logoName: 'apglogo.png',
    subDomain: 'apg.bookingpad.net',
    title: 'Orchestra Orders Tool',
    showSearch: false,
    showOrders: true,
    showSubagencies: false,
    showFooter: false,
    showPrivacy: false,
    showCoView: false,
    showSettings: false,
    showCorporates: false,
    showMaintenancePage: false
  }]
};
